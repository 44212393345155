import { isTablet } from '../utils/constants'

const CLASSES = {
    COMPONENT: '.image-video-base',
}

const OBSERVER_THRESHOLD = [0.9]
const PAUSE_TIMEOUT_DURATION = 350
/**
 * Handles the playback for images that turn into videos for mobile and desktop
 */
export default class ImageVideo {
    constructor() {
        this.elements = document.querySelectorAll(CLASSES.COMPONENT)
        this.observer = this.createInteractionObserver()
        this.timeout = 0

        this.addEventListeners()
        this.updateObservation()
    }

    createInteractionObserver() {
        return new IntersectionObserver(this.handleIntersection.bind(this), {
            threshold: OBSERVER_THRESHOLD,
        })
    }

    handleIntersection(entries) {
        entries.forEach((entry) => {
            const video = entry.target.querySelector('video')
            if (entry.isIntersecting) {
                entry.target.classList.add('active')
                this.play(video)
                return
            }

            entry.target.classList.remove('active')
            this.pause(video)

        })
    }

    updateObservation() {
        if (isTablet()) {
            this.elements.forEach((elem) => this.observer.observe(elem))
            return
        }

        this.elements.forEach((elem) => {
            this.observer.unobserve(elem)
            elem.classList.remove('active')
        })
    }

    addEventListeners() {
        window.addEventListener('resize', this.debounce(this.updateObservation, 200))

        this.elements.forEach(elem => {
            const video = elem.querySelector('video')

            elem.addEventListener('mouseenter', () => {
                if (!elem.classList.contains('active')) {
                    this.play(video)
                }
            })

            elem.addEventListener('mouseleave', () => {
                if (!elem.classList.contains('active')) {
                    this.pause(video)
                }
            })
        })
    }

    play(video) {
        window.clearTimeout(this.timeout)
        video.play()
    }

    pause(video) {
        this.timeout = window.setTimeout(() => {
            video.pause()
            video.currentTime = 0
            // This timeout should be the same as the transition duration
        }, PAUSE_TIMEOUT_DURATION)
    }

    debounce(func, wait) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => func.apply(this, args), wait)
        }
    }
}

export const ImageVideoComponent = {
    name: 'ImageVideo',
    componentClass: CLASSES.COMPONENT,
    Source: ImageVideo,
}
