import { AccordionComponent } from 'components/Accordion'
import { BackgroundFadeComponent } from 'components/BackgroundFade'
import { CategorySearchComponent } from 'components/CategorySearch'
import { CompanyFiltersComponent } from 'components/CompanyFilters'
import { ClockComponent } from 'components/Clock'
import { CookieControlComponent } from 'components/CookieControl'
import { FilterPanelComponent } from 'components/FilterPanel'
import { FounderPortraitsComponent } from 'components/FounderPortraits'
import { FounderPraiseComponent } from 'components/FounderPraise'
import { FounderSpotlightComponent } from 'components/FounderSpotlight'
import { HeroCarouselComponent } from 'components/HeroCarousel'
import { HomeHeroComponent } from 'components/HomeHero'
import { MultiImageComponent } from 'components/MultiImage'
import { RelationshipListComponent } from 'components/RelationshipList'
import { ScrollTargetComponent } from 'utils/ScrollTarget'
import { TeamFiltersComponent } from 'components/TeamFilters'
import { VideoPlayerComponent } from 'components/VideoPlayer'
import { MultiVideoComponent } from 'components/MultiVideo'
import { MicrositeComponent } from 'components/Microsite'
import { MicrositeRestrictedComponent } from 'components/MicrositeRestricted'
import { ScrollListComponent } from 'components/ScrollList'
import { ScrollToElementComponent } from 'components/ScrollToElement'
import { HomeOverlayComponent } from 'components/HomeOverlay'
import { LoadingTexturesComponent } from 'components/LoadingTextures'
import { QuoteShareComponent } from 'components/QuoteShare'
import { PopupComponent } from 'components/Popup'
import { PopupNewsletterComponent } from 'components/PopupNewsletter'
import { PressAudioComponent } from 'components/PressAudio'
import { PressPodcastComponent } from 'components/PressPodcast'
import { PressSidebarStickyComponent } from 'components/PressSidebarSticky'
import { PressThemeChooserComponent } from 'components/PressThemeChooser'
import { PressSliderComponent } from 'components/PressSlider'
import { KickerLinksComponent } from 'components/KickerLinks'
import { TabbedBlockComponent } from 'components/TabbedBlock'
import { FeaturedCompaniesCarouselComponent } from 'components/FeaturedCompaniesCarousel'
import { CompaniesScrollComponent } from 'components/CompaniesScroll'
import { ImageVideoComponent } from 'components/ImageVideo'
/**
 * Add module objects as needed
 * {
 *  name: Class Name
 *  componentClass: html .class
 *  Source: imported module
 *  render: Function (if React SSR component)
 * }
 */
export const modules = [
    AccordionComponent,
    BackgroundFadeComponent,
    CategorySearchComponent,
    CompanyFiltersComponent,
    ClockComponent,
    FeaturedCompaniesCarouselComponent,
    CookieControlComponent,
    FilterPanelComponent,
    FounderPortraitsComponent,
    FounderPraiseComponent,
    FounderSpotlightComponent,
    HeroCarouselComponent,
    HomeHeroComponent,
    HomeOverlayComponent,
    LoadingTexturesComponent,
    MultiImageComponent,
    MultiVideoComponent,
    MicrositeComponent,
    MicrositeRestrictedComponent,
    PopupComponent,
    PopupNewsletterComponent,
    PressAudioComponent,
    PressPodcastComponent,
    PressSidebarStickyComponent,
    PressThemeChooserComponent,
    PressSliderComponent,
    QuoteShareComponent,
    RelationshipListComponent,
    ScrollListComponent,
    ScrollTargetComponent,
    ScrollToElementComponent,
    TeamFiltersComponent,
    VideoPlayerComponent,
    KickerLinksComponent,
    TabbedBlockComponent,
    CompaniesScrollComponent,
    ImageVideoComponent,
]

export default modules
